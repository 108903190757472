$(document).on("turbolinks:load", function() {
  $('#store_thumbnail_1_url').change(function(e) {
    var filename = $('#store_thumbnail_1_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    var file = e.currentTarget.files[0];
    if(file.size > 1 * 1024 * 1024) {
      $('#store_thumbnail_1_url').val('');
      alert("サムネイル画像 が容量を超えています。");
      e.stopPropagation();
      return;
    }
    $('#store-img-1').html(filename);
  });

  $('#store_thumbnail_2_url').change(function(e) {
    var filename = $('#store_thumbnail_2_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    var file = e.currentTarget.files[0];
    if(file.size > 1 * 1024 * 1024) {
      $('#store_thumbnail_2_url').val('');
      alert("サムネイル画像 が容量を超えています。");
      e.stopPropagation();
      return;
    }
    $('#store-img-2').html(filename);
  });

  $('#store_thumbnail_3_url').change(function(e) {
    var filename = $('#store_thumbnail_3_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    var file = e.currentTarget.files[0];
    if(file.size > 1 * 1024 * 1024) {
      $('#store_thumbnail_3_url').val('');
      alert("サムネイル画像 が容量を超えています。");
      e.stopPropagation();
      return;
    }
    $('#store-img-3').html(filename);
  });

  $('#store_floor_map_url').change(function() {
    var filename = $('#store_floor_map_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }

    $('#fileinput').html(filename);
  });

  $('#store_floor_map_2_url').change(function(e) {
    var filename = $('#store_floor_map_2_url').val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    var file = e.currentTarget.files[0];
    if(file.size > 5 * 1024 * 1024) {
      $('#store_floor_map_2_url').val('');
      alert("フロアマップ画像 が容量を超えています。");
      e.stopPropagation();
      return;
    }
    $('#store-img-floor-map-2-url').html(filename);
  });

  $("#searchZipcode").click(function(){
    $('#store_address1').val('');
    $('#store_address2').val('');
    var zipcode = $('#store_invoice_zip').val();
    var url = 'https://geoapi.heartrails.com/api/json?method=searchByPostal&postal=' + zipcode;
    $.ajax({
      url: url,
      type: 'GET'
    }).done(function(data){
      if (data['response'] && data['response']['location']){
        var location = data['response']['location'][0];
        if (location){
          $('#store_address1').val(location['prefecture']);
          $('#store_address2').val(location['city']);
          $('#store_address3').val(location['town'].replace('一丁目', '').replace('二丁目', '').replace('丁目', ''));
        }
      }
    })
  });

  $(".js_show_block_search").unbind('click').bind('click', function() {
    var $iconExpand = $(this).find(".js_icon_expand_search");
    if ($.inArray("fa-caret-down", $iconExpand[0].className.split(/\s+/)) != -1) {
      var classExpand = "fa-caret-up";
      $(".js_show_block_search span").text('検索欄を非表示にする');
    } else {
      var classExpand = "fa-caret-down";
      $(".js_show_block_search span").text('検索欄を表示にする');
    }
    $iconExpand.removeClass("fa-caret-down fa-caret-up");
    $iconExpand.addClass(classExpand);
    $(".js_block_search").slideToggle(500);
  });

  $('body').on('click', '.js-btn-reset-store', function() {
    window.location.href = '/stores';
  });

  $('body').on('click', '.js-btn-delete-store', function() {
    sweetAleartDeleteStore($(this).data('id'));
  });

  $('button#store-form-submit').on('click', async function() {
    let isSave = true;
    if ( isStoreClosed() ) {
      isSave = await sweetAleartStoreClosed();
    }

    if(isSave){
      submitStoreForm();
    }
  })

  function submitStoreForm() {
    $('form.set_form_submitting').trigger('submit');
  }

  function isStoreClosed() {
    return parseInt(getStoreStatus()) === getStoreStatuses().closed;
  }

  function getStoreStatus() {
    return $('#store_mst_store_status_id').val();
  }

  function getStoreStatuses(){
    return $('#data-store-statuses').data('store-statuses')
  }

  function sweetAleartStoreClosed(){
    return Swal.fire(swalStoreClosed).then((result)=> {
      return result.isConfirmed;
    });
  }

  function sweetAleartResetStore() {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        window.location.href = '/stores'
      }
    });
  }

  function sweetAleartDeleteStore(store_id) {
    Swal.fire(swalCommon).then(function(result) {
      if (result.value) {
        $.ajax({
          method: 'DELETE',
          url: `/stores/${store_id}`
        });
      }
    });
  }

  // $('.stores-edit-dt').on('click', '.add_fields', function(){
  //   $(this).parent().siblings('.nested-fields.title-nested-dt').css('display', 'flex');
  // });

  $('#store_unit_price_per_tsubo').on('change paste keyup keydown', function() {
    var unit_price = $(this).val().replace(/,/g, ''),
        unit = $('#store_tsubo_num').val().replace(/,/g, '');

    $('#store_rent').val(
      parseFloat(unit_price * unit).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      })
    )
  });

  $('#store_tsubo_num').on('change paste keyup keydown', function() {
    var unit = $(this).val().replace(/,/g, ''),
        unit_price = $('#store_unit_price_per_tsubo').val().replace(/,/g, '');

    $('#store_rent').val(
      parseFloat(unit * unit_price).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      })
    )
  });

  $('body').on('click', '#load-more-stores', function() {
    let page = $(this).data('page')
    $.ajax({
      url: '/stores?page=' + page,
      type: 'get',
      success: function(data){
        $('#load_more_tbody_dtst').append($(data).find('#load_more_tbody_dtst tr'));
        $('#button_load_more_tdst').html($(data).find('#button_load_more_tdst a'));
        $('#data_size_store_js').html("該当件数:" + $('#load_more_tbody_dtst tr').length + "件");
      }
    })
    return false;
  })

  $('#store_partner_id').on('change', function() {
    $.ajax({
      type: 'GET',
      url: '/stores/render_store_cd',
      data: {'partner_id': this.value},
      dataType: 'json',
      success: function(data) {
        $('#store_store_cd').val(data["store_cd"]);
      }
    });
  });

  if($('#store_partner_id').length > 0 && $('#store_new_record_js').length > 0){
    $.ajax({
      type: 'GET',
      url: '/stores/render_store_cd',
      data: {'partner_id': $('#store_partner_id').val()},
      dataType: 'json',
      success: function(data) {
        $('#store_store_cd').val(data["store_cd"]);
      }
    });
    $('#partner_brand_name').val('');
    $.ajax({
      type: 'GET',
      url: '/stores/render_brand_by_partner',
      data: {'partner_id': $('#store_partner_id').val()},
      dataType: 'json',
      success: function(data) {
        $('#partner_brand_name').val(data["brand"]);
      }
    });
  }

  if($('.js_store_form_edit').length > 0){
    var regionJs = document.getElementById("regionJs").innerHTML,
        localJs = document.getElementById("localJs").innerHTML;

    $.ajax({
      type: 'GET',
      url: '/stores/render_mst_local',
      success: function(data) {
        var stateObject = data.arrs,
            regions = data.regions,
            js_locals = data.js_locals,
            jsRegion = document.getElementById("store_mst_region_js"),
            localName = document.getElementById("store_local_name_js");

        // region
        for (var x in stateObject) { // region name
          if(x.toString() == regionJs.toString()){
            jsRegion.options[jsRegion.options.length] = new Option(regions[x], x, true, true);
          }else{
            jsRegion.options[jsRegion.options.length] = new Option(regions[x], x);
          }
        }

        for (var y in stateObject[jsRegion.value]) { // local name
          if(y.toString() == localJs.toString()){
            localName.options[localName.options.length] = new Option(js_locals[y], y, true, true);
          }else{
            localName.options[localName.options.length] = new Option(js_locals[y], y);
          }
        }

        jsRegion.onchange = function () { // local name
          localName.length = 1;
          if (this.selectedIndex < 1) return;
          for (var y in stateObject[this.value]) {
            localName.options[localName.options.length] = new Option(js_locals[y], y, false, true);
          }
        }
      }
    });
  }

  // store list: render brand to partner
  $('#q_partner_id_eq').on('change', function() {
    $('#partner_brand_name').val('');
    $.ajax({
      type: 'GET',
      url: '/stores/render_brand_by_partner',
      data: {'partner_id': this.value},
      dataType: 'json',
      success: function(data) {
        $('#partner_brand_name').val(data["brand"]);
      }
    });
  });


  // store form: render brand to partner
  $('#store_partner_id').on('change', function() {
    $('#partner_brand_name').val('');
    $.ajax({
      type: 'GET',
      url: '/stores/render_brand_by_partner',
      data: {'partner_id': this.value},
      dataType: 'json',
      success: function(data) {
        $('#partner_brand_name').val(data["brand"]);
      }
    });
  });

  // way store
  $('.store_way_stores_attributes_thumbnail').change(function(e) {
    var filename = $(this).val();
    if (filename.substring(3,11) == 'fakepath') {
      filename = filename.substring(12);
    }
    var file = e.currentTarget.files[0];
    if(file.size > 1 * 1024 * 1024) {
      $(this).val('');
      alert("サムネイル画像 が容量を超えています。");
      e.stopPropagation();
      return;
    }
    $(this).parent().parent().siblings().find('.text_name_file_img_way_store').html(filename);
  });
});
